export class KsKaspiEndpoint {
  static defaultPath = '';

  /**
   * Tires
   */
  getCarMarks = '/yml/car-parts/tyres/car-marks';
  getCarModels = '/yml/car-parts/tyres/car-models';
  getCarYears = '/yml/car-parts/tyres/car-years';
  getCarModifications = '/yml/car-parts/tyres/car-modifications';
  getCarTyreSizes = '/yml/car-parts/tyres/car-tyre-sizes';
  getTireDimensionsByCar = '/yml/car-parts/tyres/find-by-car';

  /**
   * Car-parts
   */

  searchByVin = '/yml/car-parts/cars/search/:vin';
  carPartsCategories = '/yml/car-parts/pl/cp/categories';
  catalogOemCrosses = '/yml/car-parts/catalog/oem-crosses';
  carPartAnalogs = '/yml/car-parts/catalog/alternatives';
  // catalogUnitImage = '/yml/car-parts/catalog/unit-image';

  /**
   * Car-parts product list
   */
  carPartsProductFilters = '/yml/car-parts/pl/cp/filters';
  carPartsProductResults = '/yml/car-parts/pl/cp/results';
  carPartsProductsFilter = '/yml/car-parts/pl/cp/filter';

  constructor(path = KsKaspiEndpoint.defaultPath) {
    for (const key of Object.keys(this)) {
      this[key] = path + this[key];
    }
  }
}
