import { getFromUrl } from './getParamFromSearchQuery';
import { getCategoryHierarchyProps } from './getCategoryHierarchyProps';
import { productUnitType } from '../const/productUnitType';

export const getProductReviewParams = (data: any) => {
  const path = document.location.pathname;
  const categoryHierarchyProps = data.categories && getCategoryHierarchyProps(data.categories);

  return {
    path,
    order_id: data.orderId,
    ref: getFromUrl.ref(),
    has_configurator: data.hasConfigurator,
    product_id: data.productId,
    product_name: data.productName,
    supplier: data.supplier,
    supplier_id: data.supplierId,
    brand: data.brand,
    ...categoryHierarchyProps,
    product_type: (data.productType && productUnitType[data.productType]) || '',
    created_time: data.createdTime,
    started_by: getFromUrl.startedBy() || 'push'
  };
};
