import { hasMerchantInLink } from './hasMerchantInLink';
import { ProductLinkParam } from './mutateProductCards';

export function mutateShopLink(
    card: IItemCard,
    merchantIds?: string[]
) {
  if (!merchantIds?.length || hasMerchantInLink(card.shopLink)) {
    return;
  }

  const merchantIdQuery = merchantIds.map((id) => `&${ProductLinkParam.MERCHANT_ID}=${id}`).join('');
  card.shopLink += merchantIdQuery;
}
