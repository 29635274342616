import { cityList } from '../../helpers/locationHelpers';
import { StartedByTypes } from '../const/StartedByTypes';

export function getStartedBy(): StartedByTypes | string {
  const currentUrl = new URL(window.location.href);
  const valueFromCurrentUrl = currentUrl.searchParams.get('started_by');

  if (valueFromCurrentUrl) {
    return valueFromCurrentUrl;
  }

  // document.referrer doesn't change in SPA !!
  const referrer = document.referrer;

  if (!referrer) {
    return StartedByTypes.DirectLink;
  }

  const cityCodes = cityList.reduce<string[]>(
    (acc, charGroup) => acc.concat(charGroup.cityData.map((city) => city.code)),
    []
  );

  const url = new URL(referrer);
  const urlParts = url.pathname
    .split('/')
    .filter(
      (part) => Boolean(part) && part !== 'shop' && !cityCodes.includes(part)
    );
  const pagePath = urlParts[0];

  switch (pagePath) {
    case 'search':
      return StartedByTypes.SearchListing;
    case 'c':
      return urlParts[1] === 'categories'
        ? StartedByTypes.CategoryNavigation
        : StartedByTypes.CategoryListing;
    case 'p':
      return StartedByTypes.shopProduct;
    default:
      return pagePath;
  }
}
