export const allowedToOptimizeMerchantIds = [
  '17135017',
  '30131364',
  '8061018',
  'Europharma',
  '14918013',
  '16035104',
  '30131531',
  '10375002',
  'Sadyhan',
  '4284000',
  '9046009',
  'Dolgolet',
  '1303007',
  '13664001',
  '11707001',
  '976039',
  '9254002',
  '11265003',
  '30146392',
  '11135002',
  '16431213',
  '16195580',
  '1222002',
  '6278019',
  '16852322',
  'Tumar',
  '13662003',
  '14575022',
  '12812030',
  '12856000',
  '13182023',
  '7164002',
  '13073034',
  '30332930',
  '30131373',
  '1119003',
  '18713266',
  '30128482',
  '1109026',
  '13361001',
  '15749337',
  '30128875',
  '30131421',
  '7355001',
  '30131403',
  '13664073',
  '30131475',
  '30110391',
  '13449007',
  '30131415',
  '10310000',
];
