import { ProductLinkParam } from './mutateProductCards';

export function hasMerchantInLink(link: string) {
    try {
        const queryParams = new URLSearchParams(link);
        return queryParams.has(ProductLinkParam.MERCHANT_ID);
    } catch (error) {
        return false;
    }
}
