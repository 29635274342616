import { FilterDeliveryTime } from '../../const/FilterDelivery';
import { FilterIdTypes } from '../../const/filters';

export interface IProductListActiveFilters {
  merchantId: string;
  merchantIds: string[];
  deliveryId: FilterDeliveryTime;
}

// TODO: IFilters
export function getProductListActiveFilters(
  filters: any
): IProductListActiveFilters {
  const merchantFilterId = FilterIdTypes.ALL_MERCHANTS;
  const deliveryFilterId = 'deliveryTime';

  const result = {
    merchantId: '',
    merchantIds: [],
    deliveryId: null,
  };

  if (!filters) {
    return result;
  }

  filters.forEach((item) => {
    const isTargetFilter = [merchantFilterId, deliveryFilterId].includes(
      item.id
    );
    if (!isTargetFilter || !item.rows) {
      return;
    }

    const activeRows = item.rows.filter((row) => row.active);

    if (activeRows.length) {
      switch (item.id) {
        case merchantFilterId:
          if (activeRows.length === 1) {
            result.merchantId = activeRows[0].id.split(':').pop();
          } else {
            activeRows.forEach((i) =>
                result.merchantIds.push(i.id.split(':').pop())
            );
          }
          break;
        case deliveryFilterId:
          const id = activeRows[0].id.split(':').pop();
          result.deliveryId = id || null;
          break;
        default:
          break;
      }
    }
  });

  return result;
}
