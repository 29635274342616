declare type TSearchStartedByParamType = 'magnum' | 'main';

export function getSearchStartedByParam(
  merchantId = '',
  merchantIds?: string[]
): TSearchStartedByParamType {
  if (merchantId && typeof merchantId !== 'string') {
    throw new Error(
      'getSearchStartedByParam: merchantId must be string, getting ' +
        typeof merchantId
    );
  }
  if (merchantIds?.length && Array.isArray(merchantIds) !== true) {
    throw new Error(
      'getSearchStartedByParam: merchantIds must be [], getting ' +
        typeof merchantIds
    );
  }

  let merchant = merchantId;

  if (!merchant && merchantIds?.length === 1) {
    merchant = merchantIds[0];
  }

  return merchant?.toLowerCase() === 'magnum' ? 'magnum' : 'main';
}
