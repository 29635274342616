export enum StartedByTypes {
  TopThree = 'Top-3',
  Backdrop = 'Backdrop',
  OffersPage = 'Offers Page',
  productOffers = 'product_offers',
  BackdropWhenRemoved = 'backdrop_when_removed',
  BackdropSimilarProducts = 'backdrop_similar_products',
  SwipedLeft = 'swiped_left',
  PreselectedFilterListing = 'preselected_filter_listing',
  CrossSellBackdrop = 'cross_sell_backdrop',
  SimilarGoods = 'similar_goods',
  AnotherSupplier = 'another_supplier',
  CategoryNavigation = 'category_navigation',
  CategoryListing = 'category_listing',
  SearchListing = 'search_listing',
  ProgressBarBackdrop = 'progres_bar_backdrop',
  typ = 'TYP',
  reviewEdit = 'EDIT',
  backdropSelectOffer = 'backdrop_select_offer',
  shopProduct = 'shop_product',
  backdropOffersDetails = 'backdrop_offer_details',
  allReviews = 'all_reviews',
  DirectLink = 'direct_link',
  CarParts = 'autoparts',
}
