export const medicineMasterCategories = [
    'inflammation and infections',
    'boost immunity',
    'obstetrics and gynecology',
    'disinfectants',
    'stomach intestines liver',
    'vision',
    'skin hair nails',
    'healing creams',
    'muscles bones joints',
    'neurology',
    'allergy',
    'cold and flu',
    'antimicrobial drugs',
    'cardiovascular system',
    'endocrinology'
  ];
