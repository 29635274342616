// Название  страницы на которой происходит событие

export enum PageCategory {
  HOME = 'Home', // Главная Магазина
  CATEGORY_NAV = 'Category Navigation', // Навигационная (меню категорий)
  CATEGORY_LISTING = 'Category Listing', // Листинг категории
  SEARCH_LISTING = 'Search Listing', // Листинг поиска
  ITEM = 'Product Detail', // Страница продукта
  SELLERS = 'Sellers', // Страница оферов
  SIMILAR_PRODUCTS = 'Similar Products', // Страница продукта (блок Похожие товары продавца)
  FAVORITES = 'Favorite Products', // Избранное
  CART = 'Cart', // Корзина
  ORDER_LIST = 'Order Management', // Мои заказы
  ORDER = 'Order Details', // Детали заказа
  CHECKOUT_DELIVERY = 'Select Delivery Method', // Выбор способа доставки
  CHECKOUT_PAYMENT = 'Select Payment Method', // Выбор способа оплаты
  REFUNDS = 'Refunds', // Страница возвратов
  MERCHANT = 'Merchant', // Страница продавца
  MAGNUM_LANDING = 'Magnum',
  TYP_CHECKOUT = 'TYP_Shop',
  TYP = 'TYP',
  EMPTY_CART = 'Empty_Cart', // Пустая корзина
  SHOP_CERTIFICATE = 'shopCertificate', // Листинг Сертификатов
  SIMILAR_GOODS_BACKDROP = 'similar_goods_backdrop', // Бэкдроп на странице товара (Похожие товары)
}
