// TODO: Хелпер на время теста (когда обложки будут приходить с бэка надо будет удалить)
import { CONSTANTS } from '../../const/Constants';
import {
  ProductCoverMediaType,
  productCoverServiceInstance,
  ProductCoverType,
} from '../../services/productCoverService';

export async function setCover(cards: IItemCard[], m): Promise<void> {
  try {
    const url = `${CONSTANTS.initProductCoverInListing.existUrl}/${ProductCoverType.TEASER}`;

    const filteredCards = cards.filter((card) => card.teasers.length);

    if (!filteredCards.length) {
      return;
    }
    const response = await m.request({
      url,
      params: {
        o: filteredCards.map((card) => card.teasers.flat()).toString(),
      },
    });

    if (!response) {
      return;
    }
    cards.forEach((card) => {
      // * status === 'OK' означает что видео уже загружено и готово к использованию
      // * так же есть статус 'NEW' который говорит о том что видео есть, но еще обрабатывается
      card.teasers.forEach((teaser) => {
        if (response[teaser]?.status !== 'OK') {
          return;
        }
        card.previewImages.unshift({
          small: null,
          medium: null,
          large: null,
          coverUrl: `${CONSTANTS.initProductCoverInListing.coverUrl}/${ProductCoverType.TEASER}/${teaser}?e=MP4`,
          coverPoster: card.previewImages.find((img) => !!img.large).large,
        });
      });
    });
  } catch (error) {
    console.error(error);
  }
}
