import randomString from 'ks-utilities/lib/randomString';

import { CONSTANTS } from '../common/const/Constants';
import { BFF, GATEWAY, KS_KASPI, REFUNDS } from '../common/const/Endpoint';
import { getGeoHeaders } from '../common/helpers/getGeoHeaders';
import { getUserIdHashForPCM } from '../common/helpers/getUserIdHash';
import { HTTPMethodTypes } from '../common/RequestManager/Request';
import { SourceSystem } from '../common/services/pcm/pcmService';
import { UserGeolocationStorageService } from '../common/services/UserGeolocationStorageService';

export const SHOP_TOKEN_KEY = 'KS-Token';

export enum OfferOptions {
  PRICE = 'PRICE',
  DELIVERY = 'DELIVERY',
}

export enum RefundProcessTypes {
  TOTAL = 'TOTAL',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum ReviewStatus {
  PENDING = 'PENDING',
  CHECKED = 'CHECKED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  VERIFICATION = 'VERIFICATION',
  VERIFIED = 'VERIFIED',
  LANGUAGE_VERIFICATION = 'LANGUAGE_VERIFICATION',
}

export enum ReviewTypes {
  MERCHANT = 'MERCHANT',
  PRODUCT = 'PRODUCT',
}

export type IHeadersData = Record<string, string>;

export interface IRefundsBody {
  paging: {
    limit: number;
    page: number;
  };
  orderId?: string;
}

export interface IRefundsListParams {
  limit: number;
  page: number;
}

export interface IReviewData {
  reviewId: string;
  orderCode: string;
  productSku: string;
  merchantId: string;
  reviewStatus: ReviewStatus;
  reviewTypes?: ReviewTypes;
  isCommented: boolean;
  orderExpired?: boolean;
}

export interface IOrderReviewsAvailability {
  orderExpired: boolean;
  reviews: {
    [key: IReviewData['productSku']]: IReviewData;
  };
}

export class Api {
  m: any; // Mithril
  constructor(m) {
    this.m = m;
  }

  /**
   * content item
   */

  getProductDataMS(productCode: string): Promise<IItemProductData> {
    return this.m.request({
      url: GATEWAY.productData,
      params: {
        productCode,
        random: randomString(),
      },
    });
  }

  getProductConfiguratorMS(baseProductCode: string) {
    return this.m.request({
      url: GATEWAY.configuratorData,
      method: 'GET',
      params: {
        baseProductCode,
        random: randomString(),
      },
    });
  }

  getSizeTable(code: string) {
    return this.m.request({
      url: GATEWAY.sizeTableData,
      method: 'GET',
      params: { code },
    });
  }

  async getProductLimits(productCodes: string[]) {
    const codes = productCodes.join(',');
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.productLimits,
      params: {
        productCodes: codes,
      },
    });
  }

  /**
   * product-view
   */
  getGoods(params: IGoodsParams) {
    const { productsSkus, ignorePriceFilter = false } = params;
    const code = productsSkus.map((id) => id).join(',');

    const paramsData: IGoodsParams = {
      code,
      mUid: params.mUid,
      i: params.i,
      u: params.u,
      ipf: String(ignorePriceFilter),
      zid: params.zid,
    };

    Object.keys(paramsData).forEach((key) => {
      if (!paramsData[key]) {
        delete paramsData[key];
      }
    });

    return this.m.request({
      url: GATEWAY.productGateway,
      params: paramsData,
    });
  }

  getOrdersProduct(params: IGoodsParams) {
    const { productsSkus } = params;
    const code = productsSkus.map((id) => id).join(',');

    const paramsData: IGoodsParams = {
      code,
      mUid: params.mUid,
      i: params.i,
      u: params.u,
      zid: params.zid,
    };

    Object.keys(paramsData).forEach((key) => {
      if (!paramsData[key]) {
        delete paramsData[key];
      }
    });

    return this.m.request({
      url: GATEWAY.ordersProduct,
      params: paramsData,
    });
  }

  getAvailabilityGoods(
    productsSkus: string[],
    merchantId?: string | string[]
  ): Promise<string[]> {
    const code = productsSkus.map((id) => id).join(',');

    const merchants =
      typeof merchantId === 'string'
        ? merchantId
        : merchantId.map((id) => id).join(',');

    return this.m.request({
      url: GATEWAY.productAvailability,
      params: {
        code,
        m: merchants,
      },
    });
  }

  /**
   * offer-view
   */
  getOffersByFilterDelivery(payload: IGetOffersByFilterDeliveryPayload) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.offersByFilterGateway,
      body: payload,
    });
  }

  getOffersByDeliveryDuration(payload: IGetOffersByDeliveryDurationPayload) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.offersByFilterGateway,
      body: payload,
    });
  }

  getOffersByMerchant(payload: IGetOffersByMerchantPayload) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.offersByFilterGateway,
      body: payload,
    });
  }

  getOffersByMaster(params, body: IGetOffersByMasterPayload) {
    let headers = {};
    const userGeolocationService = new UserGeolocationStorageService();
    const geolocationSessionData =
      userGeolocationService.getGeolocationHeadersFromSession();

    if (geolocationSessionData?.headers) {
      headers = geolocationSessionData.headers;
    }

    return this.m.request({
      method: 'POST',
      url: GATEWAY.offersByMasterGateway,
      headers,
      params,
      body,
    });
  }

  /**
   * Merchant
   */

  getMerchantDetails(merchantId: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getMerchantDetails,
      params: {
        merchantId,
      },
    });
  }

  getMerchantSupport(merchantId: string, cityCode?: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getMerchantSupport,
      params: {
        merchantId,
        c: cityCode,
      },
    });
  }

  /**
   * Reviews
   */
  getReviewsMS(params: IReviewsRequestParams) {
    return this.m.request({
      url: GATEWAY.productReviews,
      params: {
        ...params,
        filter: params.filter.map((f) => f).toString(),
      },
    });
  }

  getReviewsMSByBucket(params: IProductReviewsByBucketRequestParams) {
    return this.m.request({
      url: GATEWAY.productReviewsBucket,
      params: {
        ...params,
        filter: params.filter.map((f) => f).toString(),
      },
    });
  }

  getReviewSummaryMS(productCode: string) {
    return this.m.request({
      url: GATEWAY.reviewSummary,
      params: { productCode },
    });
  }

  getReviewGroupSummaryMS(productCode: string) {
    return this.m.request({
      url: GATEWAY.reviewGroupSummary,
      params: { productCode },
    });
  }

  getMerchantReviewsMS(params: IMerchantReviewsRequestParams) {
    return this.m.request({
      url: GATEWAY.merchantReviews,
      params: {
        ...params,
        filter: params.filter.map((f) => f).toString(),
        days: CONSTANTS.merchantReviewsDaysPeriod,
      },
    });
  }

  getReviewTeasersMS(): Promise<{ data: IReviewTeaser[] }> {
    return this.m.request({
      url: GATEWAY.reviewTeasers,
    });
  }

  getReviewProductByOrder(
    orderCode: string,
    productSku: string
  ): Promise<IReviewData> {
    return this.m.request({
      url: GATEWAY.reviewProductByOrder,
      params: {
        orderCode,
        productSku,
      },
    });
  }
  getReviewBonusesByOrder(
    orderCode: string,
    productCode: string
  ): Promise<{ data: IReviewBonus }> {
    return this.m.request({
      url: GATEWAY.reviewOrderBonus,
      params: {
        orderCode,
        productCode,
      },
    });
  }

  getReviewsByOrder(
    orderCode: string
  ): Promise<{ orderExpired: boolean; reviewList: IReviewData[] }> {
    return this.m.request({
      url: GATEWAY.reviewsByOrder,
      params: {
        orderCode,
      },
    });
  }

  getReviewTags(orderCode: string): Promise<{ data: IReviewTag[] }> {
    return this.m.request({
      url: GATEWAY.reviewTags,
      params: {
        orderCode,
      },
    });
  }

  deleteReview(reviewId: string): Promise<void> {
    return this.m.request({
      method: 'DELETE',
      url: GATEWAY.deleteReview,
      params: {
        reviewId,
      },
    });
  }

  getReviewById(reviewId: string): Promise<IReview> {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.getReviewById,
      params: {
        reviewId,
      },
    });
  }

  /**
   * Postomat-book
   */
  getPostomatBook(cityId: string): Promise<IResponsePostomatBook[]> {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.getPostomatBook,
      params: {
        cityID: cityId,
      },
    });
  }

  createPostomatBook(
    body: IPayloadPostomatBook
  ): Promise<IResponsePostomatBook> {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.createPostomatBook,
      body,
    });
  }

  updatePostomatBook(posId: string, body: IPayloadPostomatBook) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.changePostomatBook,
      body,
      params: {
        posId,
      },
    });
  }

  deletePostomatBook(posId: string) {
    return this.m.request({
      method: 'DELETE',
      url: GATEWAY.changePostomatBook,
      params: {
        posId,
      },
    });
  }

  /**
   * address-book
   */
  getAddresses(
    cityId: string,
    merchantUid: string,
    shopToken?: string
  ): Promise<IResponseAddress[]> {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.getAddress,
      params: {
        cityID: cityId,
        m: merchantUid,
      },
      headers: {
        [SHOP_TOKEN_KEY]: shopToken,
      },
    });
  }

  createAddress(body: IPayloadAddress): Promise<IResponseAddress> {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.createAddress,
      body,
    });
  }

  updateAddress(addressId: string, body: IPayloadAddress) {
    return this.m.request({
      method: 'POST',
      url: GATEWAY.changeAddress,
      body,
      params: {
        addressId,
      },
    });
  }

  deleteAddress(addressId: string) {
    return this.m.request({
      method: 'DELETE',
      url: GATEWAY.changeAddress,
      params: {
        addressId,
      },
    });
  }

  //   curl -X 'GET' \
  // 'https://uatks1.kaspi.kz/yml/ms/points/api/v1/zones/point?merchantUid=Magnum&lng=76.97341&lat=43.266022' \
  //   -H 'accept: application/json'

  // curl -X 'GET' \
  //   'https://uatks1.kaspi.kz/yml/ms/points/api/v1/zones/point?merchantUid=Magnum&lng=76.97341&lat=43.266022' \
  //   -H 'accept: application/json' \
  //   -H 'Cookie: ticket=TGT-8e386c35-712f-4ff3-9cb1-a20433e84d9e'

  // address find by merchant
  getAddressZoneInfo({
    userIdHash,
    cityId,
    merchantUid,
  }: {
    userIdHash: string;
    cityId: string;
    merchantUid?: string;
  }) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getAddressZoneInfo,
      params: {
        userIdHash,
        cityId,
        merchantUid,
      },
    });
  }

  getNearestAddress(
    cityId: string,
    shopToken: string,
    lat: string,
    long: string
  ): Promise<IResponseAddress> {
    const headers = getGeoHeaders(lat, long);

    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getNearestAddress,
      headers: {
        ...headers.headers,
        [SHOP_TOKEN_KEY]: shopToken,
      },
      params: {
        cityId,
      },
    });
  }

  /**
   * Checkout
   */
  getLoanForm(checkoutId: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.loanForm,
      params: {
        checkoutId,
      },
    });
  }

  submitLoanForm(body) {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: GATEWAY.loanForm,
      body,
      config: (xhr: any) => {
        xhr.ignoreHandlingError404 = true;
      },
    });
  }

  /**
   * Refund
   */

  checkIsOrderRefundable(orderId: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: REFUNDS.refundCheck,
      params: {
        orderId,
      },
    });
  }

  getRefundReasons(productSku: string, orderId: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: REFUNDS.refundReasons,
      params: {
        productSku,
        o: orderId,
      },
    });
  }

  sendRefund(data) {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: REFUNDS.refundSend,
      body: data,
    });
  }

  getRefunds(body: IRefundsBody) {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: REFUNDS.refundsList,
      body,
    });
  }

  getRefund(id: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: REFUNDS.refund,
      params: {
        id,
      },
    });
  }

  getRefundByCode(code: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: REFUNDS.refundByCode,
      params: {
        code,
      },
    });
  }

  getSlots(refundId: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: REFUNDS.refundSlots,
      params: {
        refundId,
      },
    });
  }

  createApprovalDispute(refundId: string) {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: REFUNDS.refundApprovalDispute,
      params: {
        refundId,
      },
    });
  }

  createDecisionDispute(refundId: string) {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: REFUNDS.refundDecisionDispute,
      params: {
        refundId,
      },
    });
  }

  getRefundsCount(process: RefundProcessTypes): Promise<number> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: REFUNDS.refundsCount,
      params: {
        process,
      },
      config: (xhr: any) => {
        xhr.ignoreHandlingError404 = true;
        xhr.ignoreHandlingError500 = true;
      },
    });
  }

  cancelRefundApplication(refundId: string): Promise<void> {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: REFUNDS.refundCancelApplication,
      params: {
        refundId,
      },
    });
  }

  sendRefundDelivery(data, refundId: string) {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: REFUNDS.refundDelivery,
      params: {
        refundId,
      },
      body: data,
    });
  }

  /**
   * KL
   */
  async getCityPolygon(cityId: string): Promise<{ bounds: number[][] }> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getCityPolygon,
      params: {
        code: cityId,
      },
    });
  }

  /**
   * Merchant delivery
   */
  async getMerchantDeliverySlots(
    payload: IPayloadMerchantDeliverySlots
  ): Promise<IResponseMerchantDeliverySlots> {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.getAvailableSlots,
      params: payload,
    });
  }

  async getSpecificCategoriesSlots(): Promise<string[]> {
    return this.m.request({
      method: 'GET',
      url: GATEWAY.getSpecificCategoriesSlots,
    });
  }

  async getPostomatPoints(
    cityId: string,
    postomatIds?: string,
    entryCodes?: string
  ): Promise<{ data: { pickup_points: IKLPoint[] } }> {
    const params: {
      postomatIds?: string;
      cityCode?: string;
      productCode?: string;
    } = {};

    if (cityId) {
      params.cityCode = cityId;
    }

    if (postomatIds) {
      params.postomatIds = postomatIds;
    }

    if (entryCodes) {
      params.productCode = entryCodes;
    }

    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getPostomatPoints,
      params,
    });
  }

  async getPostomatPointsDates(
    params: IPayloadGetPickupDates
  ): Promise<{ data: { pickupPoints: IPickupDateKL[] } }> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getPostomatPointsDates,
      params,
    });
  }

  async getPostomatNeighborOptions(
    params: IPayloadPostomatNeighborOptions
  ): Promise<{
    status: { code: number; message: string };
    data: IResponsePostomatNeighborOptions;
  }> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getPostomatNeighborOptions,
      params,
      config: (xhr: any) => {
        xhr.ignoreHandlingError404 = true;
        xhr.ignoreHandlingError500 = true;
      },
    });
  }

  async getNearbyPostomat(params: INearbyPostomatPayload): Promise<{
    status: { code: number; message: string };
    data: INearbyPostomatResponse;
  }> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getNearbyPostomat,
      params,
    });
  }

  /**
   * Translations
   */
  async getTranslationsByPrefix(prefix: string): Promise<ITranslation[]> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getTranslationsByPrefix,
      params: { prefix },
    });
  }

  /**
   * Certificate
   */

  async checkCertificateRecipient(params: Record<string, string>) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: BFF.checkCertificateRecipient,
      params,
    });
  }

  /**
   * BFF Refunds
   */

  getRefundsList(params: IRefundsListParams) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: BFF.refundsList,
      params,
    });
  }

  getRefundDetails(id: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: BFF.refundDetails,
      params: {
        id,
      },
    });
  }

  getRefundDetailsByCode(code: string) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: BFF.refundDetailsByCode,
      params: {
        code,
      },
    });
  }

  getUserProfile(phone: string, config?: any) {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: BFF.userProfile,
      params: {
        phone,
      },
      config,
    });
  }

  /**
   * car-parts
   */
  async getCarMarks(): Promise<ITireFilterListResponse> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: KS_KASPI.getCarMarks,
    });
  }
  async getCarModels(
    params: IGetCarModelsPayload
  ): Promise<ITireFilterListResponse> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: KS_KASPI.getCarModels,
      params,
    });
  }
  async getCarYears(
    params: IGetCarYearsPayload
  ): Promise<ITireFilterListResponse> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: KS_KASPI.getCarYears,
      params,
    });
  }
  async getCarModifications(
    params: IGetCarModificationsPayload
  ): Promise<ITireFilterListResponse> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: KS_KASPI.getCarModifications,
      params,
    });
  }
  async getCarTireSizes(
    params: IGetCarTyreSizesPayload
  ): Promise<ITireSizesResponse> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: KS_KASPI.getCarTyreSizes,
      params,
    });
  }
  async getTireDimensionsByCar(
    params: IGetCarDimenstionsByCarPayload
  ): Promise<ITireDimensions[]> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: KS_KASPI.getTireDimensionsByCar,
      params,
    });
  }

  /**
   * Prize draw
   */

  async getPrizeDrawData() {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.getPrizeDrawData,
    });
  }

  /**
   * Discounts
   */

  async getPromotionDiscount(body = {}): Promise<IResponseDiscountSearch> {
    return this.m.request({
      method: HTTPMethodTypes.post,
      url: window.BACKEND.config.pcmBaseHost + GATEWAY.pcmDiscountSearch,
      body,
      headers: {
        userIdHash: getUserIdHashForPCM(),
        'Source-system': SourceSystem.kaspiShop,
      },
    });
  }

  async getAbTestsScript(code: string): Promise<IResponseCertificateTips> {
    return this.m.request({
      method: HTTPMethodTypes.get,
      url: GATEWAY.abTestsScript,
      params: {
        code,
      },
    });
  }
}
