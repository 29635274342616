import { IAnalyticEventRawData } from '../AnalyticService';
import { PageCategory } from '../const/PageCategory';
import { combineListingEventProperties } from '../helpers/combineListingEventProperties';
import { combineOffersFiltersEventProperties } from '../helpers/combineOffersFiltersEventProperties';
import { combineProductEventProperties } from '../helpers/combineProductEventProperties';
import { getCategoryHierarchyArr } from '../helpers/getCategoryHierarchyArr';
import { getCategoryHierarchyProps } from '../helpers/getCategoryHierarchyProps';
import { getPageCategory } from '../helpers/getPageCategory';
import { getProductPromo } from '../helpers/getProductPromo';
import { getStartedBy } from '../helpers/getStartedBy';

export class TestEventsMapper {
  m: any; // mithril

  constructor(m: any) {
    this.m = m;
  }

  sendEvent(eventData: IAnalyticEventRawData) {
    window.ksService.AnalyticService.log(eventData);
  }

  unfoldCategoryTags(data: { treeCategory: ICategoryTree; tags: string[] }) {
    const eventName = 'test_shop_category_unfold';

    const categoryHierarchyArr = getCategoryHierarchyArr(data.treeCategory);
    const categoryHierarchyProps =
      getCategoryHierarchyProps(categoryHierarchyArr);

    const parameters = {
      page_category: getPageCategory(document.location.pathname),
      ...categoryHierarchyProps,
      tags: data.tags,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedButtonConvenientReturn(data: IParamConvenientReturn) {
    const eventName = 'test_shop_return_button_view';

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(data.product.category);

    const parameters = {
      product_id: data.productId,
      ...categoryLevels,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedBackdropConvenientReturn(data: IParamConvenientReturn) {
    const eventName = 'test_shop_return_backdrop_click';

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(data.product.category);

    const parameters = {
      product_id: data.productId,
      ...categoryLevels,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedBackdropConvenientReturn(data: IParamConvenientReturn) {
    const eventName = 'test_shop_return_backdrop_view';

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(data.product.category);

    const parameters = {
      product_id: data.productId,
      ...categoryLevels,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  closeBackdropConvenientReturn(data: IParamConvenientReturn) {
    const eventName = 'test_shop_return_backdrop_close';

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(data.product.category);

    const parameters = {
      product_id: data.productId,
      ...categoryLevels,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedProductDescriptionTest(data: IParamViewedProductPage) {
    const eventName = 'test_shop_product_description';
    const globalPromotion = data.promo;
    const hasConfigurator = data.hasConfigurator;
    const { promo = null } = getProductPromo(globalPromotion);

    const parameters = {
      ...combineProductEventProperties(data),
      promo,
      has_configurator: !!hasConfigurator,
      is_discount: data.discountType,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedOffersSortBtn(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_sort_click';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  appliedOffersSorting(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_sort_apply';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedOffersDeliveryFiltersBackdrop(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_delivery_backdrop';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  appliedOffersFilter(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_filter_apply';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  resetOffersFilter(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_filter_reset';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  startedOffersSearchQuery(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_search_query_start';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedOffersSearchResult(data: IOffersFiltersAnalyticParams) {
    const eventName = 'test_shop_product_search_results_view';

    const parameters = {
      ...combineOffersFiltersEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedLandingDresses(categories: string[]) {
    const eventName = 'test_shop_dresses_viewed_landing';

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(categories);

    const parameters = {
      ...categoryLevels,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedModelLandingDresses(categories: string[]) {
    const eventName = 'test_shop_dresses_clicked_model';

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(categories);

    const parameters = {
      ...categoryLevels,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedBrandLandingDresses(categories: string[]) {
    const eventName = 'test_shop_dresses_clicked_topbrand';
    const brandName = categories[categories.length - 1];
    categories.pop();

    const { category_level, category_string, ...categoryLevels } =
      getCategoryHierarchyProps(categories);

    const parameters = {
      ...categoryLevels,
      brand_name: brandName,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedSimilarProductsBackdrop(data: ISimilarProductsAnalyticParams) {
    const eventName = 'test_similar_goods_backdrop';
    const { category_level_1, category_level_2, category_level_3 } =
      getCategoryHierarchyProps(data.categories);

    const parameters = {
      product_id: data.productId,
      category_level_1,
      category_level_2,
      category_level_3,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedZoomInButton(data: ISimilarProductsAnalyticParams) {
    const eventName = 'test_zoom_in';
    const { category_level_1, category_level_2, category_level_3 } =
      getCategoryHierarchyProps(data.categories);

    const parameters = {
      product_id: data.productId,
      category_level_1,
      category_level_2,
      category_level_3,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedCarPartsNavigation(hasHistory: boolean) {
    const eventName = 'test_shop_autoparts_navigation';
    const parameters = {
      started_by: getStartedBy(),
      has_history: hasHistory,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnCarPartsSelectionTab(selectionType: string) {
    const eventName = 'test_shop_autoparts_select_tab';
    const parameters = { tab: selectionType };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnCarPartsSelectionInput(selectionType: string) {
    const eventName = 'test_shop_autoparts_navigation_selection';
    const parameters = { tab: selectionType, started_by: getStartedBy() };

    this.sendEvent({ event: eventName, parameters });
  }

  closedCarPartsSelectionBackdrop(selectionType: string) {
    const eventName = 'test_shop_autoparts_backdrop_close';
    const parameters = { tab: selectionType };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnCarPartsSelectionSearchResultEntry(parameters) {
    const eventName = 'test_shop_autoparts_select_find_result';

    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnCarPartsSelectModification(parameters) {
    const eventName = 'test_shop_autoparts_select_modification';

    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnCarPartsSelectionSearchHistoryEntry(parameters) {
    const eventName = 'test_shop_autoparts_select_history';

    this.sendEvent({ event: eventName, parameters });
  }

  filteredCarPartsCategoriesBySelectedAuto(parameters) {
    const eventName = 'test_shop_autoparts_navigation_result';

    this.sendEvent({ event: eventName, parameters });
  }

  viewedCarPartsListing({ tab, carPartsParams, data }) {
    const eventName = 'test_shop_autoparts_listing_view';
    const parameters = {
      tab,
      ...carPartsParams,
      ...combineListingEventProperties(data),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnCarPartsSelectionBanner(data) {
    const eventName = 'test_shop_autoparts_click_selection_outside';
    const pageCategory = getPageCategory(window.location.pathname);
    const parameters = {
      page_category: pageCategory,
      ...(pageCategory === PageCategory.ITEM
        ? getCategoryHierarchyProps(data.product?.category)
        : combineListingEventProperties(data)),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  optimizeDividedCartsToggleViewed(data) {
    const eventName = 'test_shop_pharma_toggle_view';
    const pageCategory = getPageCategory(window.location.pathname);

    const parameters = {
      type: data.type,
      amount_to_pay: data.amountToPay,
      page_category: pageCategory,
      suborder_count: data.suborderCount,
      merchant_name: data.merchantName,
      merchant_id: data.merchantId,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  optimizeDividedCartsToggleClicked(data) {
    const eventName = 'test_shop_pharma_toggle_click';
    const pageCategory = getPageCategory(window.location.pathname);

    const parameters = {
      amount_to_pay: data.amountToPay,
      page_category: pageCategory,
      suborder_count: data.suborderCount,
      action: data.action,
      merchant_name: data.merchantName,
      merchant_id: data.merchantId,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  optimizeDividedCartsBackdropViewed(data) {
    const eventName = 'test_shop_pharma_backdrop_view';
    const pageCategory = getPageCategory(window.location.pathname);

    const parameters = {
      amount_to_pay: data.amountToPay,
      page_category: pageCategory,
      suborder_count: data.suborderCount,
      merchant_name: data.merchantName,
      merchant_id: data.merchantId,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  optimizeDividedCartsBackdropAgreed(data) {
    const eventName = 'test_shop_pharma_backdrop_agreed';
    const pageCategory = getPageCategory(window.location.pathname);

    const parameters = {
      amount_to_pay: data.amountToPay,
      page_category: pageCategory,
      suborder_count: data.suborderCount,
      merchant_name: data.merchantName,
      merchant_id: data.merchantId,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  optimizeDividedCartsBackdropNotAgreed(data) {
    const eventName = 'test_shop_pharma_backdrop_notagreed';
    const pageCategory = getPageCategory(window.location.pathname);

    const parameters = {
      amount_to_pay: data.amountToPay,
      page_category: pageCategory,
      suborder_count: data.suborderCount,
      merchant_name: data.merchantName,
      merchant_id: data.merchantId,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  optimizeDividedCartsSendRequest(data) {
    const eventName = 'test_shop_pharma_cart_reassemble_request';
    const pageCategory = getPageCategory(window.location.pathname);

    const parameters = {
      page_category: pageCategory,
      suborder_count: data.suborderCount,
      merchant_name: data.merchantName,
      merchant_id: data.merchantId,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  optimizeDividedCartsGotResponse(data) {
    const eventName = 'test_shop_pharma_cart_reassemble_response';
    const pageCategory = getPageCategory(window.location.pathname);

    const parameters = {
      has_combination: data.hasCombination,
      page_category: pageCategory,
      suborder_count: data.suborderCount,
      merchant_name: data.merchantName,
      merchant_id: data.merchantId,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedCartPageWithOptimize(data) {
    const eventName = 'test_shop_cart';

    const parameters = {
      cart_id: data.cartId,
      category_level_1: data.category_level_1,
      category_level_2: data.category_level_2,
      merchant_id: data.merchantIds,
      subtotal_price: data.subtotalPrice,
      total_price: data.totalPrice,
    };

    this.sendEvent({ event: eventName, parameters });
  }
}
